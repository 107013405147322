import shopifyGenericDefaults from '../shopify-generic/config.js';

import { showModal, hideModal } from './quickViewDrawer.js';
import { clickOnCheckbox, initCompareBanner } from './compareDrawer.js';

window.Convermax.closeQuickView = () => hideModal();
window.Convermax.quickView = (e) => showModal(e);
window.Convermax.clickOnCheckbox = (e) => clickOnCheckbox(e);

const baseUpdateCallback = shopifyGenericDefaults.Widgets.find(
  (w) => w.name === 'SearchResult',
)?.updateCallback;

function updateCallback() {
  baseUpdateCallback?.();
  initCompareBanner();
}

function responseHandler(response) {
  const { Actions: [{ Type, RedirectUrl: url } = {}] = [] } = response;

  const { pathname } = window.location;

  if (Type === 'Redirect' && url && pathname !== url) {
    window.location.assign(url);
    return;
  }

  return response;
}

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  pagination: {
    edgeRange: 0,
    centralRange: 1,
    disableDelimeters: true,
  },
  defaultView: 'grid',
  responseHandler,
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter(
      (w) => !['HeaderVehicleWidget', 'SearchResult'].includes(w.name),
    ),
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm-ymm',
      isAlwaysColumnLayout: true,
      template: 'fitmentSearch/homeVehicleWidget',
    },
    {
      name: 'SearchResult',
      infiniteScrollDisabled: !!window.Convermax.config?.infiniteScrollDisabled,
      updateCallback,
    },
    {
      name: 'CustomSearchPage',
      type: 'SearchPage',
      location: {
        lastChildOf: 'body',
        class: 'cm_hide',
      },
      template: 'SearchPage',
      visibleIf: () =>
        window.location.pathname.includes('/pages/') &&
        !window.document.querySelector('#cm_results, #cm-brand-list, #cm_manufacturers'),
    },
  ],
};
