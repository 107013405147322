export default {
  BUTTON_PRIMARY_CLASS: 'button',
  BUTTON_SECONDARY_CLASS: 'button',

  SELECT_YOUR_VEHICLE_BLOCK: 'Shop by Vehicle',

  SEARCH_BOX_INPUT_CLASS: 'search__input field__input',
  SEARCH_BOX_BUTTON_CLASS: 'search__button field__button',

  PRODUCT_REPEATER_CLASS: 'product-grid grid--3-col-desktop',
  RELATED_PRODUCT_LIST_CLASS:
    'grid product-grid product-grid-recommendations contains-card contains-card--product grid--4-col-desktop grid--2-col-tablet-down',

  FACET_TITLE_ICON: `
  <rt-virtual rt-if="this.isCollapsed" template="icons/angle-down" />
  <rt-virtual rt-if="!this.isCollapsed" template="icons/angle-up" />`,

  START_OVER: 'Remove all',

  GARAGE_SIZE: '<span class="garage-size cart-count-bubble" key="garage-size">{{size}}</span>',
};
